import Breakpoint from '@core/scripts/helper/breakpoint.js';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import Accordion from '@bonprix/pattern-library/modules/pattern-library/accordion'; // Accordion liegt im pattern-library namespace
import Messages from '@core/scripts/components/messages';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope} = JshModule('footer/footer-accordion');


const moduleEnabledForBreakpoints = ['small', 'medium'];
let accordion;
let initialized = false;

if (Breakpoint.keyIsIn(moduleEnabledForBreakpoints)) {
    handleAccordionItemBinding();
}

MessageBroker.subscribe(Messages.WINDOW.RESIZED, handleAccordionItemBinding);
MessageBroker.subscribe(Messages.FOOTER.REINIT_ACCORDION, reinit);


function handleAccordionItemBinding() {
    if (Breakpoint.keyIsIn(moduleEnabledForBreakpoints)) {
        if (!initialized) {
            Accordion(moduleScope, { 'disableOpenFirstItem': true, 'initializeExternally': false });
            initialized = true;
        }
    } else {
        if (initialized) {
            initialized = false;
        }
    }
}

function reinit() {
    if (initialized) {
        //accordion.removeBindings();
        initialized = false;
    }
    Accordion(moduleScope, { 'disableOpenFirstItem': true, 'initializeExternally': false });
    //accordion.init();
    initialized = true;
}